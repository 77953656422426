import { Image, Input, List, Tooltip } from "antd";
import React from "react";

const ExceptionHome = () => {
  const searcHResultList = ["A000123", "A000134", "A000156"];
  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header  border-bottom-dashed">
                  <h3 className="card-title">
                    User's & Processed Item's Location(s)
                  </h3>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <div className="row d-flex align-items-center">
                        <div className="col-4 text-end">
                          <label className="fw-bold">User's Location :</label>
                        </div>{" "}
                        <div className="col-8">
                          <Input size="large" className="w-100" />
                        </div>
                      </div>
                      <div className="row d-flex align-items-center mt-2">
                        <div className="col-4 text-end">
                          <label className="fw-bold">No Listing :</label>
                        </div>{" "}
                        <div className="col-8">
                          <Input size="large" className="w-100" />
                        </div>
                      </div>{" "}
                      <div className="row d-flex align-items-center mt-2">
                        <div className="col-4 text-end">
                          <label className="fw-bold">No fnSKU :</label>
                        </div>{" "}
                        <div className="col-8">
                          <Input size="large" className="w-100" />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row d-flex align-items-center">
                        <div className="col-4 text-end">
                          <label className="fw-bold">Send to FBA :</label>
                        </div>{" "}
                        <div className="col-8">
                          <Input size="large" className="w-100" />
                        </div>
                      </div>
                      <div className="row d-flex align-items-center mt-2">
                        <div className="col-4 text-end">
                          <label className="fw-bold">Return to Source :</label>
                        </div>{" "}
                        <div className="col-8">
                          <Input size="large" className="w-100" />
                        </div>
                      </div>{" "}
                      <div className="row d-flex align-items-center mt-2">
                        <div className="col-4 text-end">
                          <label className="fw-bold">
                            Ship to Buyer / Client :
                          </label>
                        </div>{" "}
                        <div className="col-8">
                          <Input size="large" className="w-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <div className="card">
                <div className="card-header  border-bottom-dashed">
                  <h3 className="card-title">Item Search</h3>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4 mb-3">
                      <label className="fw-bold">Inventory Item ID</label>
                      <Input size="large" className="w-100" />
                    </div>{" "}
                    <div className="col-4 mb-3">
                      <label className="fw-bold">Client </label>
                      <Input size="large" className="w-100" />
                    </div>{" "}
                    <div className="col-4 mb-3">
                      <label className="fw-bold">Tracking </label>
                      <Input size="large" className="w-100" />
                    </div>
                    <div className="col-4 mb-3">
                      <label className="fw-bold">Order </label>
                      <Input size="large" className="w-100" />
                    </div>
                    <div className="col-8 mb-3">
                      <label className="fw-bold">Title </label>
                      <Input size="large" className="w-100" />
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-3">
                      <List
                        size="small"
                        header={<h3 className="card-title">Search Result</h3>}
                        footer={""}
                        dataSource={searcHResultList}
                        renderItem={(item) => (
                          <List.Item
                            onClick={() => {
                              //   alert("Item Selected: " + item);
                            }}
                            className="cursor-pointer"
                          >
                            {item}
                          </List.Item>
                        )}
                        style={{
                          border: "1px solid #d9d9d9",
                          borderRadius: "7px",
                        }}
                        className="p-4"
                      />
                    </div>
                    <div className="col-9">
                      <div
                        className="row p-4"
                        style={{
                          border: "1px solid #d9d9d9",
                          borderRadius: "7px",
                        }}
                      >
                        <div className="col-12 d-flex align-items-start justify-content-between">
                          <div className="d-flex align-items-center">
                            <h3 className="fw-bold me-2 mb-0">
                              {/* Inventory Item UID:  */}
                              A000001L
                            </h3>
                            <Tooltip title="Inventory Item Status">
                              <button
                                className="btn btn-light text-dark btn-sm"
                                style={{ backgroundColor: "#323c5920" }}
                              >
                                Received & Ready to Prep
                              </button>
                            </Tooltip>
                          </div>
                          <div>
                            <div className="d-flex align-items-center">
                              <h3 className="fw-bold me-2 mb-0">
                                Client:
                                {/* Really Neat Deals, LLC-Robert Strand */}
                                #13
                                {/* {inventoryOrderData?.client_account?.seller_name}
                                                   <span className="mx-2">-</span>
                                                   {inventoryOrderData?.client_account?.name} #
                                                   {inventoryOrderData?.client_account?.id} */}
                              </h3>
                              <Tooltip title="Client Status">
                                <button
                                  className="btn btn-light text-dark ms-4 btn-sm"
                                  style={{ backgroundColor: "#323c5920" }}
                                >
                                  Active
                                  {/* {
                                  inventoryOrderData?.client_account
                                    ?.client_account_status
                                } */}
                                </button>
                              </Tooltip>
                            </div>
                            <button className="btn btn-primary btn-sm mt-3">
                              See Location History
                            </button>
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="row">
                            <div className="col-2">
                              <h3 className="card-title">Next Action</h3>
                              <button className="btn btn-primary btn-sm my-1">
                                Open Inventory Item
                              </button>
                              <button className="btn btn-primary btn-sm my-1">
                                Open Related Order
                              </button>
                            </div>
                            <div className="col-10">
                              <div className="d-flex">
                                <div className="d-flex flex-column align-items-center">
                                  <Image
                                    src="/assets/media/staticBookImage/depositphotos_12376816-stock-photo-stack-of-old-books.jpg"
                                    style={{
                                      width: "100px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  <button
                                    className="btn btn-primary btn-sm mt-1"
                                    style={{ width: "165px" }}
                                  >
                                    Take / Update Picture
                                  </button>
                                </div>
                                <div className="ms-5">
                                  <div className="row">
                                    <div className="col-12 ">
                                      <label className="fs-6">
                                        <span className="fw-bold me-2">
                                          Item Name/Title :
                                        </span>
                                        Culturally Competent Practice: A
                                        Framework for Understanding Diverse
                                        Groups and Justice Issues
                                      </label>
                                    </div>
                                    <div className="col-12 mt-2">
                                      <label className="fs-6">
                                        <span className="fw-bold me-2">
                                          Item's Current Location :
                                        </span>
                                        Ste 5: Back Cab A: Shelf 1
                                      </label>
                                    </div>
                                    <div className="col-12 mt-2">
                                      <label className="fs-6">
                                        <span className="fw-bold me-2">
                                          Latest Location Scan TimeStamp :
                                        </span>
                                        08-23-2024 10:26 AM
                                      </label>
                                    </div>{" "}
                                    <div className="col-12 mt-2">
                                      <label className="fs-6">
                                        <span className="fw-bold me-2">
                                          Barcode :
                                        </span>
                                        9780123456789
                                      </label>
                                    </div>
                                    <div className="col-12 mt-2">
                                      <label className="fs-6">
                                        <span className="fw-bold me-2">
                                          ASIN :
                                        </span>
                                        0123456789
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <button className="btn btn-primary btn-sm">
                            Print Label Amazon FBA
                          </button>
                          <button className="btn btn-primary btn-sm ms-3">
                            Print Label Item UID Tracking
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExceptionHome;
