import axiosCall from "../../../../configurations/network-services/axiosCall";

// export const GetItemListAction = (data) => {
//   const path = `inventory-items/get-items?page=${data?.page}&per-page=${data?.perPage}&search_text=${data?.search}`;
//   const responseType = "GET_ITEM_LIST";
//   return axiosCall("get", path, responseType, null, {
//     Authorization: `Bearer ${localStorage.getItem("token")}`,
//   });
// };

export const UpdateReceiveItemAction = (id, data) => {
  const path = `inventory-items/update-receive-item/${id}`;
  const responseType = "UPDATE_RECEIVE_ITEM";
  return axiosCall("POST", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionSendToAmazonFba = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SEND_TO_AMAZON_FBA" });
};
