import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResetPassword from "../../modules/pages/reset-password";
import {
  resetPasswordAction,
  fakeActionAuth,
} from "../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  ResetPasswordResponse: state.Auth.ResetPasswordResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPasswordAction,
      fakeActionAuth,
    },
    dispatch
  );

const ResetPassword_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default ResetPassword_;
