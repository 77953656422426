import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default function () {
  return (
    <div id="kt_app_footer" className="app-footer">
      <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack">
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">
            {moment().format("YYYY")} ©
          </span>
          <Link
            target="_blank"
            className="text-gray-800 text-hover-primary fw-bold"
          >
            ezbookprep
          </Link>
        </div>
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item pe-20">
            <Link target="_blank" className="menu-link px-2">
              About
            </Link>
          </li>
          {/* <li className="menu-item">
                        <Link target="_blank" className="menu-link px-2">Support</Link>
                    </li> */}
        </ul>
      </div>
    </div>
  );
}
