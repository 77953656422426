import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-menu-light {
    .ant-menu-item {
      .ant-menu-item-icon,
      .ant-menu-title-content > a {
        font-size: 14px;
        color: #273b5b !important;
      }
    }
  }
  .ant-menu-title-content {
    font-size: 14px;
  }
  .ant-menu-light {
    background: transparent;
    border-inline-end: none !important;
    .ant-menu-item-selected {
      background-color: #273b5b;
      box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
      //   -webkit-animation-name: pulse;
      //   animation-name: pulse;
      //   -webkit-animation-duration: 2s;
      //   animation-duration: 2s;
      //   -webkit-animation-fill-mode: both;
      //   animation-fill-mode: both;
      .ant-menu-title-content > span,
      .ant-menu-item-icon,
      svg {
        color: #fff !important;
      }
    }
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item-selected {
      line-height: 48px !important;
      padding-inline: calc(50% - 10px - 4px) !important;
      box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
      //   -webkit-animation-name: pulse;
      //   animation-name: pulse;
      //   -webkit-animation-duration: 2s;
      //   animation-duration: 2s;
      //   -webkit-animation-fill-mode: both;
      //   animation-fill-mode: both;
    }
  }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background: #273b5b;
    // -webkit-animation-name: pulse;
    // animation-name: pulse;
    // -webkit-animation-duration: 2s;
    // animation-duration: 2s;
    // -webkit-animation-fill-mode: both;
    // animation-fill-mode: both;
    box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
    .ant-menu-item-icon,
    .ant-menu-title-content,
    .ant-menu-submenu-arrow {
      color: #fff !important;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-item-selected {
      background: #273b5b;
      //   -webkit-animation-name: pulse;
      //   animation-name: pulse;
      //   -webkit-animation-duration: 2s;
      //   animation-duration: 2s;
      //   -webkit-animation-fill-mode: both;
      //   animation-fill-mode: both;
      box-shadow: rgb(198 198 198 / 59%) 0px 10px 20px 0px;
      .ant-menu-item-icon,
      .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #fff !important;
      }
    }
  }

  .ant-menu-item-selected {
    color: #fff !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: #d2d6dc !important;
  }
  .ant-menu-light
    .ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
    outline: 0px solid #d2d6dc !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: #d2d6dc !important;
  }
  .ant-menu-light .ant-menu-item:not(.ant-menu-item-disabled):focus-visible {
    outline: 0px solid #d2d6dc !important;
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title:hover {
      background-color: #273b5b !important;
    }
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
    color: #fff !important;
  }
`;
