import axiosCall from "../../configurations/network-services/axiosCall";

// export const loginAction = (payload) => {
//   const path = `login`;
//   const responseType = "LOGIN";
//   const body = payload;
//   return axiosCall("post", path, responseType, body);
// };

export const fakeActionExceptionHome = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_EXCEPTION_HOME" });
};
