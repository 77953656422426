import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
  const path = `login`;
  const responseType = "LOGIN";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const forgotPasswordAction = (payload) => {
  const path = `forgot-password`;
  const responseType = "FORGOT_PASSWORD";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};
export const resetPasswordAction = (token, payload) => {
  const path = `reset-password?token=${token}`;
  const responseType = "RESET_PASSWORD";
  const body = payload;
  return axiosCall("put", path, responseType, body);
};

export const fakeActionAuth = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AUTH" });
};
