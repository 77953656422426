import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import axios from "axios";
import AntWarningModalWrapper from "./style";
import { Link } from "react-router-dom";

export const AppId = "";
export const EndPoint = "https://api.ezbookmanager.com/";
// export const EndPoint = "http://3.130.72.185/ez_prep_api/";
export const returnURL = "";
export const clientID = "";
export const timeZone = "America/Los_Angeles";
export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date * 1000) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};

export const clearLocalData = () => {
  // localStorage.clear();
  // window.location.assign("/login");
  // window.location.reload();
};

export const handleStorageChange = (e) => {
  e.stopPropagation();
  if (e.url === window.location.href) {
    if (!e.key) {
      clearLocalData();
    } else {
      localStorage.setItem(e.key, e.oldValue);
    }
  }
  if (
    !localStorage.getItem("token") ||
    !localStorage.getItem("user") ||
    !localStorage.getItem("userType") ||
    !localStorage.getItem("client-list") ||
    !localStorage.getItem("adminData")
  ) {
    clearLocalData();
  }
};

export const initialCall = async () => {
  axios({
    url: `${EndPoint}api/v1/all-users?no_pagination=true`,
    method: "GET",
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  })
    .then((res) => {
      localStorage.setItem(
        "client-list",
        JSON.stringify(res.data.data.records) || []
      );
    })
    .catch((error) => {
      if (error.response.data.status === 401) {
        if (error.response.data.name === "Unauthorized") {
          localStorage.clear();
          window.location.href = "/login";
        } else if (error.response.data.refresh_expire) {
          return error.response;
        }
      } else {
        return error.response;
      }
    });
};

export const us_Region = [
  {
    label: "Alabama",
    value: "Alabama",
  },
  {
    label: "Alaska",
    value: "Alaska",
  },
  {
    label: "Arizona",
    value: "Arizona",
  },
  {
    label: "Arkansas",
    value: "Arkansas",
  },
  {
    label: "California",
    value: "California",
  },
  {
    label: "Colorado",
    value: "Colorado",
  },
  {
    label: "Connecticut",
    value: "Connecticut",
  },
  {
    label: "Delaware",
    value: "Delaware",
  },
  {
    label: "District of Columbia",
    value: "District of Columbia",
  },
  {
    label: "Florida",
    value: "Florida",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Hawaii",
    value: "Hawaii",
  },
  {
    label: "Idaho",
    value: "Idaho",
  },
  {
    label: "Illinois",
    value: "Illinois",
  },
  {
    label: "Indiana",
    value: "Indiana",
  },
  {
    label: "Iowa",
    value: "Iowa",
  },
  {
    label: "Kansas",
    value: "Kansas",
  },
  {
    label: "Kentucky",
    value: "Kentucky",
  },
  {
    label: "Louisiana",
    value: "Louisiana",
  },
  {
    label: "Maine",
    value: "Maine",
  },
  {
    label: "Maryland",
    value: "Maryland",
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
  },
  {
    label: "Michigan",
    value: "Michigan",
  },
  {
    label: "Minnesota",
    value: "Minnesota",
  },
  {
    label: "Mississippi",
    value: "Mississippi",
  },
  {
    label: "Missouri",
    value: "Missouri",
  },
  {
    label: "Montana",
    value: "Montana",
  },
  {
    label: "Nebraska",
    value: "Nebraska",
  },
  {
    label: "Nevada",
    value: "Nevada",
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
  },
  {
    label: "New Jersey",
    value: "New Jersey",
  },
  {
    label: "New Mexico",
    value: "New Mexico",
  },
  {
    label: "New York",
    value: "New York",
  },
  {
    label: "North Carolina",
    value: "North Carolina",
  },
  {
    label: "North Dakota",
    value: "North Dakota",
  },
  {
    label: "Ohio",
    value: "Ohio",
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
  },
  {
    label: "Oregon",
    value: "Oregon",
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
  },
  {
    label: "South Carolina",
    value: "South Carolina",
  },
  {
    label: "South Dakota",
    value: "South Dakota",
  },
  {
    label: "Tennessee",
    value: "Tennessee",
  },
  {
    label: "Texas",
    value: "Texas",
  },
  {
    label: "Utah",
    value: "Utah",
  },
  {
    label: "Vermont",
    value: "Vermont",
  },
  {
    label: "Virginia",
    value: "Virginia",
  },
  {
    label: "Washington",
    value: "Washington",
  },
  {
    label: "West Virginia",
    value: "West Virginia",
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
  },
  {
    label: "Wyoming",
    value: "Wyoming",
  },
];

export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(([_, value]) => value)
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);
        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};

// export const WarningMessage = (message, title = "") => {
//   return (
//     <AntWarningModalWrapper>
//       {Modal.warning({
//         title: title,
//         content: (
//           <div>
//             <div>
//               <ExclamationCircleOutlined
//                 style={{
//                   fontSize: "24px",
//                   color: "#faad14",
//                   display: "flex",
//                   justifyContent: "center",
//                 }}
//               />
//             </div>
//             <div>{message}</div>
//           </div>
//         ),
//         icon: null,
//         // style: {
//         //   display: "flex",
//         //   justifyContent: "center",
//         //   alignItems: "center",
//         // }, // Centers the entire modal
//         bodyStyle: {
//           padding: "20px",
//         }, // Centers text inside the modal content
//         okButtonProps: {
//           style: {
//             display: "flex",
//             justifyContent: "center",
//             margin: "0 auto",
//             backgroundColor: "#273B5B",
//             color: "#fff",
//             borderColor: "#273B5B",
//           }, // Center the OK button
//         },
//       })}
//     </AntWarningModalWrapper>
//   );
// };

export const WarningMessage = (message, title = "") => {
  return Modal.warning({
    title: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ExclamationCircleOutlined
          style={{ color: "#faad14", fontSize: "24px", marginRight: "8px" }}
        />
        <span>{title}</span>
      </div>
    ),
    content: (
      <div style={{ textAlign: "center", fontSize: "16px", color: "#333" }}>
        {message}
      </div>
    ),
    icon: null, // Remove the default icon since we're adding a custom one in the title
    // centered: true, // Center the modal
    okText: "OK",
    okButtonProps: {
      style: {
        backgroundColor: "#273B5B", // Customize the OK button color
        color: "#fff",
        borderColor: "#273B5B",
        width: "80px", // Adjust width to look similar to your example
        textAlign: "center",
        margin: "0 auto", // Center the button
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
      },
    },
    bodyStyle: {
      padding: "24px", // Adjust modal content padding
    },
    style: {
      borderRadius: "8px", // Round corners of the modal
    },
  });
};

export const displayConditionData = (data) => {
  const statisData = {
    UsedLikeNew: {
      header: "As Ordered",
      content: "Item Received as Expected",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    UsedVeryGood: {
      header: "Below than Ordered",
      content: "Item Received In 1 condition? lower than Ordered",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    UsedGood: {
      header: "Better than Ordered",
      content: "Item Received In better condition than Ordered",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    UsedAcceptable: {
      header: "Used - Acceptable",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            {"{"}" "{"}"}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z"
              fill="#0fd236"
            />
            {"{"}" "{"}"}
          </g>
        </svg>
      ),
      color: "#7e91ff",
    },
    CollectibleLikeNew: {
      header: "As Ordered",
      content: "Item Received as Expected",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    CollectibleVeryGood: {
      header: "Below than Ordered",
      content: "Item Received In 1 condition? lower than Ordered",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    CollectibleGood: {
      header: "Collectible - Good",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    CollectibleAcceptable: {
      header: "Collectible - Acceptable",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            {"{"}" "{"}"}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z"
              fill="#0fd236"
            />
            {"{"}" "{"}"}
          </g>
        </svg>
      ),
      color: "#7e91ff",
    },
    New: { header: "New", content: "New", icon: "up", color: "#5aca5a" },
    NotAcceptable: {
      header: "Not Acceptable",
      content: "Item should not be sold",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z"
              fill="#ff2929"
            />
          </g>
        </svg>
      ),
      color: "#e46c6c",
    },
    Unknown: {
      header: "Unknown",
      content: "Unknown",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z"
              fill="#ff2929"
            />
          </g>
        </svg>
      ),
      color: "#e46c6c",
    },
  };
  return statisData?.[data];
};

export const displayConditionWIthoutOrderData = (data) => {
  const statisData = {
    UsedLikeNew: {
      header: "Used Like New",
      content: "Item Received as Expected",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    UsedVeryGood: {
      header: "Used Very Good",
      content: "Item Very Good",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            {"{"}" "{"}"}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z"
              fill="#5aca5a"
            />
            {"{"}" "{"}"}
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    UsedGood: {
      header: "Used Good",
      content: "Item Good",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    UsedAcceptable: {
      header: "Used Acceptable",
      content: "Item Acceptable",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z"
              fill="#4141bd"
            />
          </g>
        </svg>
      ),
      color: "#4141bd",
    },
    CollectibleLikeNew: {
      header: "Collectible Like New",
      content: "Item Received as Expected",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    CollectibleVeryGood: {
      header: "Collectible Very Good",
      content: "Item Received In 1 condition? lower than Ordered",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    CollectibleGood: {
      header: "Collectible Good",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    CollectibleAcceptable: {
      header: "Collectible Acceptable",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            {"{"}" "{"}"}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071C19.3166 12.0976 18.6834 12.0976 18.2929 11.7071L13 6.41421V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V6.41421L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071C3.90237 11.3166 3.90237 10.6834 4.29289 10.2929L11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3Z"
              fill="#0fd236"
            />
            {"{"}" "{"}"}
          </g>
        </svg>
      ),
      color: "#7e91ff",
    },
    New: { header: "New", content: "New", icon: "up", color: "#5aca5a" },
    NotAcceptable: {
      header: "Not Acceptable",
      content: "Item should not be sold",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z"
              fill="#ff2929"
            />
          </g>
        </svg>
      ),
      color: "#e46c6c",
    },
    Unknown: {
      header: "Unknown",
      content: "Unknown",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3C12.5523 3 13 3.44772 13 4V17.5858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L11 17.5858V4C11 3.44772 11.4477 3 12 3Z"
              fill="#ff2929"
            />
          </g>
        </svg>
      ),
      color: "#e46c6c",
    },
  };
  return statisData?.[data];
};

export const displayMarkData = (data) => {
  const newData = {
    "May have minimal": {
      header: "May have minimal",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    "May have": {
      header: "May have",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    "Clean (No marks)": {
      header: "Clean (No marks)",
      content: "",
      icon: (
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path
              d="M4 12.6111L8.92308 17.5L20 6.5"
              stroke="#1aea33"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    "Light/Minimal": {
      header: "Light to Minimal",
      content: "",
      icon: (
        <svg
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill="#000000"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <title>warning-filled</title>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="add"
                fill="#8a97db"
                transform="translate(32.000000, 42.666667)"
              >
                <path
                  d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M224,272 C208.761905,272 197.333333,283.264 197.333333,298.282667 C197.333333,313.984 208.415584,325.248 224,325.248 C239.238095,325.248 250.666667,313.984 250.666667,298.624 C250.666667,283.264 239.238095,272 224,272 Z M245.333333,106.666667 L202.666667,106.666667 L202.666667,234.666667 L245.333333,234.666667 L245.333333,106.666667 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    "Moderate/Light": {
      header: "Light to Moderate",
      content: "",
      icon: (
        <svg
          viewBox="0 0 512 512"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill="#000000"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <title>warning-filled</title>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="add"
                fill="#8a97db"
                transform="translate(32.000000, 42.666667)"
              >
                <path
                  d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M224,272 C208.761905,272 197.333333,283.264 197.333333,298.282667 C197.333333,313.984 208.415584,325.248 224,325.248 C239.238095,325.248 250.666667,313.984 250.666667,298.624 C250.666667,283.264 239.238095,272 224,272 Z M245.333333,106.666667 L202.666667,106.666667 L202.666667,234.666667 L245.333333,234.666667 L245.333333,106.666667 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    "Heavy/Moderate": {
      header: "Moderate to Heavy",
      content: "",
      icon: (
        <svg
          fill="#d62e2e"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          id="warning-alt-2"
          className="icon glyph"
          stroke="#d62e2e"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path d="M23,11,18.35,3a2,2,0,0,0-1.73-1H7.38A2,2,0,0,0,5.65,3L1,11a2,2,0,0,0,0,2l4.62,8a2,2,0,0,0,1.73,1h9.24a2,2,0,0,0,1.73-1L23,13A2,2,0,0,0,23,11ZM12,18a1,1,0,1,1,1-1A1,1,0,0,1,12,18Zm1-5a1,1,0,0,1-2,0V7a1,1,0,0,1,2,0Z" />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
    "Used Textbooks with Sticker(s)": {
      header: "Used Textbooks with Sticker(s)",
      content: "",
      icon: "",
      color: "#5aca5a",
    },
    null: {
      header: "None",
      content: "",
      icon: (
        <svg
          fill="#d62e2e"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          id="warning-alt-2"
          className="icon glyph"
          stroke="#d62e2e"
          style={{ width: "42px" }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g id="SVGRepo_iconCarrier">
            <path d="M23,11,18.35,3a2,2,0,0,0-1.73-1H7.38A2,2,0,0,0,5.65,3L1,11a2,2,0,0,0,0,2l4.62,8a2,2,0,0,0,1.73,1h9.24a2,2,0,0,0,1.73-1L23,13A2,2,0,0,0,23,11ZM12,18a1,1,0,1,1,1-1A1,1,0,0,1,12,18Zm1-5a1,1,0,0,1-2,0V7a1,1,0,0,1,2,0Z" />
          </g>
        </svg>
      ),
      color: "#5aca5a",
    },
  };
  return data ? newData?.[data] : "None";
};

export const displayProblemData = (data) => {
  return {};
};

export const numberWithCommas = (x) => {
  if (x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // return x.toLocaleString();
    }
    return x;
  }
  return x;
};

export const GetLinks = (path, label) => {
  return <span>{label}</span>;
  return (
    // <Link
    //   id={Math.random()}
    //   to={{
    //     pathname: path,
    //   }}
    // >
    { label }
    // </Link>
  );
};

export const SuccessMessage = (message, title = "") => {
  return Modal.success({
    title: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CheckCircleOutlined
          style={{ color: "green", fontSize: "24px", marginRight: "8px" }}
        />
        <span>{title}</span>
      </div>
    ),
    content: (
      <div style={{ textAlign: "center", fontSize: "16px", color: "#333" }}>
        {message}
      </div>
    ),
    icon: null, // Remove the default icon since we're adding a custom one in the title
    // centered: true, // Center the modal
    okText: "OK",
    okButtonProps: {
      style: {
        backgroundColor: "#273B5B", // Customize the OK button color
        color: "#fff",
        borderColor: "#273B5B",
        width: "80px", // Adjust width to look similar to your example
        textAlign: "center",
        margin: "0 auto", // Center the button
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
      },
    },
    bodyStyle: {
      padding: "24px", // Adjust modal content padding
    },
    style: {
      borderRadius: "8px", // Round corners of the modal
    },
  });
};
