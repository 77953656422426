import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ForgotPassword from "../../modules/pages/forgot-password";
import {
  forgotPasswordAction,
  fakeActionAuth,
} from "../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  ForgotPasswordResponse: state.Auth.ForgotPasswordResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPasswordAction,
      fakeActionAuth,
    },
    dispatch
  );

const ForgotPassword_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);

export default ForgotPassword_;
