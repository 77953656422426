import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "./core/asyncComponent";

import Login_ from "./containers/login";
import Page_ from "./modules/Page";
import SignUp_ from "./containers/sign-up";
import ForgotPassword_ from "./containers/forgor-password";
import ResetPassword_ from "./containers/reset-password";

function AppRoute(props) {
  return (
    <BrowserRouter history={props.history}>
      <Suspense
        fallback={
          <div className="overlayLoading">
            <div className="loader"></div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/login" component={asyncComponent(Login_)} />
          <Route exact path="/sign-up" component={asyncComponent(SignUp_)} />
          <Route
            exact
            path="/forgot-password"
            component={asyncComponent(ForgotPassword_)}
          />{" "}
          <Route
            exact
            path="/reset-password"
            component={asyncComponent(ResetPassword_)}
          />
          <Route path="/" component={asyncComponent(Page_)} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoute;
