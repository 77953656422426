export const nameObject = {
  // Dahsboard
  "/": {
    name: "Main Dashboard",
  },
  "/profile": {
    name: "My Profile",
    sub_link: "/profile",
  },
  "/delivery-scanning": {
    name: "Delivery Scanning",
    sub_link: "/dashboard/delivery-scanning",
  },
  "/receiving-home": {
    name: "Receiving Home",
    sub_link: "/dashboard/receiving-home",
  },
  "/exceptions-home": {
    name: "Exceptions Home",
    sub_link: "/dashboard/exceptions-home",
  },
  "/shipments": {
    name: "Shipments",
    sub_link: "/dashboard/shiping-home/shipments",
  },
  "/customer-support-home": {
    name: "Customer Support Home",
    sub_link: "/dashboard/customer-support-home",
  },
  "/sales-home": {
    name: "Sales Home",
    sub_link: "/dashboard/sales-home",
  },
  "/billing-home": {
    name: "Billing Home",
    sub_link: "/dashboard/billing-home",
  },
  "/hr-home": {
    name: "HR Home",
    sub_link: "/dashboard/hr-home",
  },
  // Receive Items
  "/receiving-items": {
    name: "Receive Items",
    sub_link: "/dashboard/receiving-home/receiving-items",
  },

  "/receiving-picture-info": {
    name: "Receiving Picture Info",
    sub_link: "/dashboard/receiving-home/receiving-picture-info",
  },

  // Inventory Order
  "/inventory-order": {
    name: "Inventory Order",
    sub_link: "/inventory-order",
  },
  "/inventory-order/view": {
    name: "Inventory Order",
    sub_link: "/inventory-order/view",
  },

  // Inventory Items
  "/inventory-items": {
    name: "Inventory Items",
    sub_link: "/inventory-items",
  },
  "/inventory-items/view": {
    name: "Inventory Items",
    sub_link: "/inventory-items/view",
  },
  "/inventory-items/status/add": {
    name: "Receive Items",
    sub_link: "/inventory-items/status/add",
  },
  "/inventory-items/send-to-amazon-fba": {
    name: "Send To Amazon FBA",
    sub_link: "/inventory-items/send-to-amazon-fba",
  },

  // Amazon Listings
  "/amazon-listings": {
    name: "Amazon Listings",
    sub_link: "/amazon-listings",
  },

  // Prep Batches
  "/prep-batches": {
    name: "Prep Batches",
    sub_link: "/prep-batches",
  },
  "/prep-batches/add": {
    name: "Add Prep Batches",
    sub_link: "/prep-batches/add",
  },
  "/prep-batches/add-batch-items": {
    name: "Add Items to Batch",
    sub_link: "/prep-batches/add-batch-items",
  },

  // Sources
  "/sources": {
    name: "Sources",
    sub_link: "/sources",
  },
  "/sources/add": {
    name: "Sources",
    sub_link: "/sources/add",
  },
  "/sources/edit": {
    name: "Sources",
    sub_link: "/sources/edit",
  },
  "/sources/view": {
    name: "Sources",
    sub_link: "/sources/view",
  },

  // Client Account
  "/client-account": {
    name: "Client Account",
    sub_link: "/entities/client-account",
  },
  "/client-account/details": {
    name: "Client Account Details",
    sub_link: "/entities/client-account/details",
  },
  "/client-account/add-prep-center": {
    name: "Prep Center Details",
    sub_link: "/entities/client-account/add-prep-center",
  },

  // Prep Staff
  "/prep-staff": {
    name: "Prep Staff",
    sub_link: "/entities/prep-staff",
  },

  // Locations
  "/locations": {
    name: "Locations",
    sub_link: "/entities/prep-centers/locations",
  },
  "/locations/add": {
    name: "Add Location",
    sub_link: "/entities/prep-centers/locations",
  },
  "/locations/edit": {
    name: "Update Location",
    sub_link: "/entities/prep-centers/locations",
  },

  // Scan Deliveries
  "/scan-deliveries": {
    name: "Scan Deliveries",
    sub_link: "/scan-deliveries",
  },

  // Add Items to Location
  "/add-item-to-location": {
    name: "Add Item to Location",
    sub_link: "/add-item-to-location",
  },

  // Admin Settings
  "/system-dashboard": {
    name: "System Dashboard",
    sub_link: "/admin/system-dashboard",
  },
  "/cron-manager": {
    name: "Cron Manager",
    sub_link: "/admin/cron-manager",
  },
  "/cron-manager/add": {
    name: "Add Cron Manager",
    sub_link: "/admin/cron-manager/add",
  },
  "/cron-manager/edit": {
    name: "Update Cron Manager",
    sub_link: "/admin/cron-manager/edit",
  },
  "/pre-receiving-report": {
    name: "Pre Receiving Report",
    sub_link: "/admin/other-reports/pre-receiving-report",
  },
  "/shell-listing-panel": {
    name: "Shell Listing Panel",
    sub_link: "/admin/other-reports/shell-listing-panel",
  },
  "/item-pick-list": {
    name: "Item Pick List",
    sub_link: "/admin/other-reports/item-pick-list",
  },
  "/system-users": {
    name: "System Users",
    sub_link: "/admin/system-users",
  },
  "/logs": {
    name: "Logs",
    sub_link: "/admin/logs",
  },
  "/my-user": {
    name: "My User",
    sub_link: "/my-user",
  },
  "/my-account": {
    name: "My Account",
    sub_link: "/my-account",
  },

  // Help
  "/help": {
    name: "Help",
    sub_link: "/help",
  },
};
