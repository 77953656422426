import { Menu, Select, message } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";
import { EndPoint } from "../../../config";
import { AdminMenus } from "./lib/menus";
import { Wrapper } from "./style";

export default function (props) {
  const { hide, screenSize, deviceType } = props;
  const history = useHistory();
  const location = useLocation();
  const contextVar = useContext(GlobalContext);
  const user_ = contextVar?.data?.profile;
  const [selectedClient, setSelectedClient] = useState("");
  const [currentMenu, setCurrentMenu] = useState(location?.pathname);

  useEffect(() => {
    console.log("location", location?.pathname?.split("/"));
    const currentMenu = location?.pathname?.split("/")?.[1];
    setCurrentMenu(`/${currentMenu}`);
  }, [location]);

  const handleSwitchUser = async (user_id) => {
    message.destroy();
    message.loading("Switching...", 0);
    setSelectedClient(user_id);
    axios({
      url: `${EndPoint}api/v1/switch-user`,
      method: "POST",
      data: { id: user_id },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        message.destroy();
        if (res?.data?.code === 200 && res?.data?.status) {
          history.push("/");
          // setTimeout(() => {
          //     history.push('/')
          // }, 300);
          let SwitchUserRes = res?.data?.data;
          let adminToken = localStorage.getItem("token");
          let adminData = localStorage.getItem("user");
          const data = {
            token: adminToken,
            data: adminData,
          };
          localStorage.setItem("adminData", JSON.stringify(data));
          let all_client = localStorage.getItem("client-list");
          all_client = all_client ? JSON.parse(all_client) : [];
          let selected_client_data = all_client.filter((e) => e.id === user_id);
          selected_client_data =
            selected_client_data.length > 0 ? selected_client_data[0] : "0";
          const userData = {
            ...SwitchUserRes?.user_data,
            client_account_id: selected_client_data?.client_account_id,
          };
          localStorage.setItem(
            "user",
            JSON.stringify(userData ? userData : SwitchUserRes?.user_data)
          );
          localStorage.setItem("userType", SwitchUserRes?.user_data?.user_type);
          localStorage.setItem(
            "token",
            SwitchUserRes?.auth_token_data?.access_token
          );
          contextVar?.updateCommonGlobalVal(
            "profile",
            userData ? userData : SwitchUserRes?.user_data
          );
          message.success("User switch successfully");
        }
      })
      .catch((error) => {
        if (error.response.data.status === 401) {
          if (error.response.data.name === "Unauthorized") {
            localStorage.clear();
            window.location.href = "/login";
          } else if (error.response.data.refresh_expire) {
            return error.response;
          }
        } else {
          return error.response;
        }
      });
  };

  const ClientOptionList = useMemo(() => {
    let all_client = localStorage.getItem("client-list");
    all_client = all_client ? JSON.parse(all_client) : [];
    if (all_client.length > 0) {
      setSelectedClient(user_?.id);
      all_client = all_client.reverse();
      return all_client.map((option) => ({
        label: `${option?.company_name ? `${option?.company_name} -` : ""} ${
          option?.user_type ? `${option?.user_type} -` : ""
        } ${option?.name ? `${option?.name}` : ""}`,
        value: option?.id,
      }));
    } else {
      return [];
    }
  }, [localStorage.getItem("client-list")]);

  return (
    <div
      id="kt_app_sidebar"
      className={`app-sidebar flex-column ${
        screenSize && !hide ? "drawer drawer-start drawer-on" : ""
      } `}
      style={{ overflow: "hidden" }}
      data-kt-drawer="true"
      data-kt-drawer-name="app-sidebar"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="250px"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
    >
      <Wrapper>
        <>
          <div
            className="app-sidebar-header d-flex flex-stack d-none d-lg-flex pt-5 pb-4 justify-content-center border-bottom border-bottom-dashed border-gray-300"
            id="kt_app_sidebar_header"
          >
            <Link to="/" className="app-sidebar-logo">
              <img
                alt="Logo"
                src="/assets/media/logo-bookprep.png"
                className="h-60px d-none d-sm-inline app-sidebar-logo-default theme-light-show shimmer"
              />
            </Link>
          </div>
          <div className="mt-3 mx-5 select-marketplace">
            {localStorage.getItem("userType") == 1 && (
              <div className="app-navbar-item ms-1 ms-md-3">
                <Select
                  size="large"
                  showSearch
                  value={selectedClient}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{
                    width: "100%",
                  }}
                  className="fs-7 br-40 header-client"
                  onChange={(value) => {
                    handleSwitchUser(value);
                  }}
                  options={ClientOptionList}
                />
              </div>
            )}
          </div>
          <div
            className="app-sidebar-navs flex-column-fluid pb-6"
            id="kt_app_sidebar_navs"
          >
            <div
              id="kt_app_sidebar_navs_wrappers"
              className="app-sidebar-wrapper my-2"
              data-kt-scroll="true"
              data-kt-scroll-activate="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-dependencies="#kt_app_sidebar_header"
              data-kt-scroll-wrappers="#kt_app_sidebar_navs"
              data-kt-scroll-offset="5px"
            >
              <div
                id="#kt_app_sidebar_menu"
                data-kt-menu="true"
                data-kt-menu-expand="false"
                className="app-sidebar-menu-primary menu menu-column menu-rounded menu-sub-indention menu-state-bullet-primary"
                style={{
                  overflowY: "auto",
                  height: "calc(-300px + 100vh)",
                  padding: "0px 5px",
                }}
              >
                <Menu
                  items={AdminMenus}
                  mode="inline"
                  selectedKeys={[currentMenu]}
                  theme="light"
                  onClick={(e) => {
                    console.log(e, "-eee");
                    history.replace(e?.key);
                    setCurrentMenu(e?.key);
                  }}
                />
              </div>
            </div>
            <div
              className="text-center pc-user-card"
              style={{
                position: "absolute",
                width: "100%",
                bottom: 0,
                left: 0,
                right: "30px",
              }}
            >
              <div
                className="m-4 p-3 py-5 rounded"
                style={{
                  background:
                    "url(/assets/media/svg/shapes/wave-bg-dark.svg) #f2f2f2",
                }}
              >
                <div>
                  <div
                    className="cursor-pointer symbol symbol-circle symbol-45px symbol-md-65px"
                    style={{ border: "1px solid #f05423", padding: "5px" }}
                  >
                    <img src={user_?.photo || ""} alt="user" />
                  </div>
                </div>
                <div>
                  <div className="d-flex flex-column mt-3">
                    <div className="fw-bold d-flex align-items-center fs-5 justify-content-center text-primary">
                      {user_?.name || ""}
                    </div>
                    <span className="fw-semibold text-muted fs-7 text-gray-700">
                      {user_?.email || ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Wrapper>
    </div>
  );
}
