export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "LOGIN_SUCCESS":
    case "LOGIN_ERROR":
      return {
        ...state,
        LoginResponse: action.updatePayload,
      };
    case "FORGOT_PASSWORD_SUCCESS":
    case "FORGOT_PASSWORD_ERROR":
      return {
        ...state,
        ForgotPasswordResponse: action.updatePayload,
      };
    case "RESET_PASSWORD_SUCCESS":
    case "RESET_PASSWORD_ERROR":
      return {
        ...state,
        ResetPasswordResponse: action.updatePayload,
      };

    case "FAKE_ACTION_AUTH":
      return [];
    default:
      return state;
  }
};
