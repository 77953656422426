export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_USER_PROFILE_DETAILS_SUCCESS":
    case "GET_USER_PROFILE_DETAILS_ERROR":
      return {
        ...state,
        UserProfileDetailsResponse: action.updatePayload,
      };
    case "UPDATE_USER_PROFILE_DETAILS_SUCCESS":
    case "UPDATE_USER_PROFILE_DETAILS_ERROR":
      return {
        ...state,
        UpdateUserProfileResponse: action.updatePayload,
      };
    case "CHANGE_USER_PASSWORD_SUCCESS":
    case "CHANGE_USER_PASSWORD_ERROR":
      return {
        ...state,
        ChangeUserPasswordResponse: action.updatePayload,
      };

    case "FAKE_ACTION_USER_PROFILE":
      return [];
    default:
      return state;
  }
};
