import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ExceptionHome from "../../modules/pages/exception-homes/exception-home";
import { fakeActionExceptionHome } from "../../redux/modules/exception-home/exception.action";

const mapStateToProps = (state) => ({
  // ForgotPasswordResponse: state.ExceptionHome.ForgotPasswordResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // forgotPasswordAction,
      fakeActionExceptionHome,
    },
    dispatch
  );

const ExceptionHome_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExceptionHome);

export default ExceptionHome_;
