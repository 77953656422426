import { createGlobalStyle } from "styled-components";

const AppStyleWrapper = createGlobalStyle`
.loader {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 5px solid var(--kt-primary);
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: rot5 1s infinite;
}
.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.shimmer {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
        100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
}
@keyframes shimmer {
    100% {
        -webkit-mask-position: left;
    }
}
@keyframes rot5 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
    border-top-color: var(--kt-primary);
    border-bottom-color: var(--kt-primary);
    border-right-color: transparent;
    border-left-color: transparent;
  }

  100% {
    transform: rotate(360deg);
  }
}
.ant-message-top {
    z-index: 99999999999999999999 !important;
}
/* loading end */


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  scroll-behavior: smooth;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.order-img img:hover {
    transition: 0.3s;
    width: 75px !important;
}

.ant-pagination {

    // .ant-pagination-item-ellipsis {
    //     color: var(--bs-primary-inverse) !important;
    // }
    // .ant-pagination-item, .ant-pagination-item-link {
    //     color: var(--bs-pagination-color) !important;
    // }
    .ant-pagination-item-active {
        background-color: var(--bs-primary-active) !important;
        border-color: var(--bs-primary-active) !important;
        color: var(--bs-primary-inverse) !important;
    }
}
.ant-dropdown-menu {
    background-color: var(--kt-menu-dropdown-bg-color) !important;
    box-shadow: var(--kt-menu-dropdown-box-shadow) !important;
}
.ant-dropdown {
    font-family:unset;
    .ant-dropdown-arrow:before {
        background: var(--kt-menu-dropdown-bg-color) !important;
    }
}
.ant-dropdown-menu-item {
    color: var(--kt-text-gray-700) !important;
    &:hover {
        color: var(--kt-menu-link-color-active) !important;
        background-color: var(--kt-menu-link-bg-color-active) !important;
        .svg-icon {
            color: var(--kt-menu-link-color-active) !important;
        }
    }
}

.ant-dropdown-menu-item-selected {
    transition: color .2s ease;
    color: var(--kt-menu-link-color-active) !important;
    background-color: var(--kt-menu-link-bg-color-active) !important;
    .svg-icon {
        color: var(--kt-menu-link-color-active) !important;
    }
}
#root {
    
    height: 100%;
}
#root > .globalHeight {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.ant-form-item .ant-form-item-label >label {
    color: var(--bs-body-color) !important;
}
.ant-empty-description {
    color: var(--bs-body-color) !important;
}
.ant-input-number {
    border: 1px solid var(--bs-gray-300) !important;
    background-color: var(--bs-body-bg)  !important;
}
.ant-input-number.ant-input-number-status-error {
    border-color: #ff4d4f !important;
}
.ant-input-number.ant-input-number-focused{
    border: 1px solid var(--bs-gray-800) !important;
    box-shadow: unset !important;
}
.ant-picker-input {
    input {
    color: var(--kt-input-solid-color) !important;
    }
    svg {
            color: var(--kt-primary) !important;
            background: var(--kt-menu-dropdown-bg-color) !important;
        }
}
.ant-picker-panel-container  {
    background-color: var(--bs-body-bg) !important;
    .ant-picker-time-panel-cell-inner {
    color: var(--kt-input-solid-color) !important;
    }
    .ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
            background-color: var(--bs-component-active-bg) !important;
            color: var(--bs-component-active-color) !important;
        }
    }
}
.ant-input-password {
    background-color: var(--kt-input-solid-bg)  !important;
    border: 1px solid var(--kt-light-inverse) !important;
    .ant-input {
        background-color: var(--kt-input-solid-bg)  !important;
        border: 1px solid transparent !important;
    }
}
.overlayLoading {
    margin: auto;
    width: fit-content;
    height: fit-content;
    inset: 0;
    position: absolute;
    z-index: 99999999;
}
.ant-radio-group {
    label {
        span {
            color: var(--kt-input-solid-color) !important;
        }
    }
}
#kt_modal_add, #kt_modal_add_customer {
    background: #0000004f;
}
.ant-upload-list-item-error {
    border-color: var(--kt-light-inverse) !important;
}
.ant-popover-arrow-content {
    --antd-arrow-background-color: var(--kt-page-bg) !important;
}
.ant-popover {
    background:#fff !important;
    .ant-popover-arrow:before {
        background: var(--kt-page-bg) !important;
    }
    .ant-popover-inner {
        background-color: var(--kt-page-bg) !important;
        .ant-popconfirm-message {
            color: var(--kt-table-color) !important;
        }
        .ant-popconfirm-description {
            color: var(--kt-text-muted) !important;
            b {
                color: var(--kt-table-color) !important;
            }
        }
    }
    .ant-popconfirm-buttons {
        .ant-btn-default {
            background-color: var(--kt-light) !important;
            color: var(--kt-text-muted)  !important;
            border: none !important;
        }
        .ant-btn-primary {
            color: var(--kt-danger-inverse) !important;
            background-color: var(--kt-danger) !important;
            border: none !important;
        }
    }
}
.ant-message {
    color: var(--kt-primary-inverse) !important;
}
a {
    cursor: pointer;
}
.ant-upload-list-item {
        .ant-upload-list-item-actions > a:first-child {
            display: none !important;
        }
}
.ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
}
.ant-select.header-client .ant-select-selector{
    border-radius: 40px !important;
}
.ant-select .ant-select-selector .ant-select-selection-item{
    color: var(--bs-input-color) !important;
	font-size: .95rem !important;
    font-weight: 400 !important;
}
.ant-select .ant-select-selector .ant-select-selection-placeholder {
    font-size:15px;
}
.ant-modal .ant-modal-content{
    padding:0
}
.ant-modal {
    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1.75rem 1.75rem;
        border-bottom: 1px solid var(--bs-border-color);
        border-top-left-radius: 0.475rem;
        border-top-right-radius: 0.475rem;
    }
    .ant-modal-footer {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: calc(1.75rem - 0.5rem * .5);
        border-top: 1px solid var(--bs-border-color);
        border-bottom-right-radius: 0.475rem;
        border-bottom-left-radius: 0.475rem;
    }
}
:where(.css-dev-only-do-not-override-a7mxd6).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #0d0d0d !important;
}

.ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-presets ul {
    color: var(--bs-gray-600) !important;
}
.ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels{
    .ant-picker-header button{
        color: var(--bs-gray-800) !important;
    }
    .ant-picker-body table thead tr th{
        color: var(--bs-gray-800) !important;
    }
    .ant-picker-body table tbody tr .ant-picker-cell{
        color: var(--bs-gray-400) !important;
    }
    .ant-picker-body table tbody tr .ant-picker-cell.ant-picker-cell-in-view{
        color: var(--bs-gray-700) !important;
    }
    .ant-picker-body table tbody tr {
        .ant-picker-cell-in-range:before{
            background-color: var(--bs-component-hover-bg) !important;
        }
        .ant-picker-cell-range-start:before{
            background:  var(--bs-component-hover-color);
        }
        .ant-picker-cell-range-end:before{
            background:  var(--bs-component-hover-color);
        }
        .ant-picker-cell-range-hover-start:before{
            background: unset;
        }
        .ant-picker-cell-range-start .ant-picker-cell-inner{
            background-color: var(--bs-component-hover-color) !important;
        }
        .ant-picker-cell-range-end .ant-picker-cell-inner{
            background-color: var(--bs-component-hover-color) !important;
        }
    }
    .ant-picker-body table tbody tr .ant-picker-cell-in-range .ant-picker-cell-inner{
        color:  var(--bs-component-hover-color) !important;
    }
}
.custom-model .modal-body {
    height: 500px;
    overflow: auto;
}
.ant-drawer .ant-drawer-content-wrapper {
    height: 70% !important;
    width: unset !important;
}
.ant-picker-dropdown .ant-picker-content{
    .ant-picker-cell-selected .ant-picker-cell-inner{
        background: var(--bs-component-active-bg) !important;
    }
}
.ant-picker-dropdown .ant-picker-footer{
    .ant-picker-ok button{
        background-color: var(--bs-primary) !important;
    }
    .ant-picker-now a:hover{
        color: var(--bs-component-active-bg) !important;
    }
}
.error-msg{
    color: rgba(255, 0, 0, 0.815);
}
.image-stack {
    position: relative;
    width: 100%;
    margin-left:10px;
}
.image-stack__item--bottom {
    position: absolute;
    right: 20px;
    top: 0;
    width: 80%;
}
  
.image-stack__item--top {
    padding-top: 10px; 
    padding-right: 20%;
    width: 80%;
}
.prep-staff .ant-skeleton {
    .ant-skeleton-title{
        width: 50% !important;
    }
    .ant-skeleton-paragraph li {
        width: 100% !important;
    }
}
.bg-pettern {
    background-color: #273b5b;
    opacity: 1;
    /* background-image:  linear-gradient(#2a4165 1.5px, transparent 1.5px), linear-gradient(to right, #2a4165 1.5px, #273b5b 1.5px); */
    background-size: 30px 30px;
}
.ocrloader video{
    padding: 15px;
    height: 195px;
    width: 300px;
}
.drawingBuffer{
    display:none;
}
.mobile-helper.active .svg-icon {
    rotate: 270deg;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: var(--bs-primary-active);
}
.nav-pills .nav-link {
    background: var(--bs-app-bg-color);
    cursor: pointer;
    color: var(--bs-input-color)
}
.nav-pills .nav-link.shipping-nav {
    /* color: var(--bs-nav-pills-link-active-color); */
    background: var(--bs-input-solid-bg-focus);
    cursor: pointer;
}
.nav-pills .nav-link.shipping-nav.active {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg);
}
.client-model .modal-body {
    margin-top: 8px;
    height: 700px;
    overflow: auto;
  } 
.round-border-all{
    border: 2px dashed var(--bs-border-color);
}
.camera-options .options{
    padding : 10px;
    border: 2px dashed var(--bs-border-color);
    border-radius: 10px;
}
.camera-options .form-check-label.selected .options{
    border: 2px dashed var(--bs-text-info);
}
.steps{
    width: 200px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}
.steps .text-content{
    text-wrap: balance;
    padding:10px;
    line-height:20px;
    font-size:15px;
}
.add-item-prep .ant-steps-item .ant-steps-item-container{
    max-width: 350px;
    transform: translateX(-40px);
    .ant-steps-item-icon{
        margin-top:15px;
        transform: translateX(245px);
        // position: absolute;
        // right: 260px;
    }
    .ant-steps-item-content .ant-steps-item-title:after{
        margin-top:15px;
        inset-inline-start: 115%;
    }
}
@media (min-width:992px) {
    .app-container {
        padding-left: 20px !important;
        padding-right: 20px !important
    }
}

@media (max-width:991.98px) {
    .app-container {
        max-width: none;
        padding-left: 20px !important;
        padding-right: 20px !important
    }
}

@media (min-width: 992px) {
    [data-kt-app-sidebar-minimize="on"][data-kt-app-sidebar-hoverable="true"]
        .app-sidebar:not(:hover)
        .app-sidebar-navs
        .pc-user-card {
        display: none;
    }
}
@media (min-width: 992px) {
    [data-kt-app-sidebar-minimize="on"][data-kt-app-sidebar-hoverable="true"]
        .app-sidebar:not(:hover)
        .app-sidebar-header
        .app-sidebar-logo {
        display: block;
    }
}
[data-kt-app-sidebar-minimize="on"][data-kt-app-sidebar-hoverable="true"] .app-sidebar:not(:hover) .app-sidebar-header .app-sidebar-logo img {
    width: 55px;
    height: 50px;
    object-fit: cover;
    object-position: left;
}
#kt_app_content{
    padding-top: 0px !important;
}
@media only screen and (max-width: 600px) {
    .extra-small{
        width: 50% !important;
    }
    .scan-list .scan-list-order{
        margin-top: -30px;
    }
    .add-item-prep {
        .ant-steps-item-tail{
            transform: translateX(80px) !important;
        }
        .ant-steps-item-content{
            display: inline-block !important;
            transform: translateY(-45px);
        }
        .ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
            transform: translateX(210px);
        }
    }
    .add-item-prep.shipment-batch .ant-steps-item-container{
        transform: translateX(0px) !important;
    }
}
// @media (min-width: 576px){
//     .add-item-prep {
//         .ant-steps-item-tail{
//             transform: translateX(80px) !important;
//         }
//         .ant-steps-item-content{
//             display: inline-block !important;
//             transform: translateY(-45px);
//         }
//         .ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
//             transform: translateX(210px);
//         }
//     }
// }
 .ant-modal-confirm-paragraph {
    max-width: 100% !important;
  }

.btn:disabled{
    cursor: no-drop !important;
    pointer-events: unset !important;
}
      .popoverActionIconSingleLine {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }
    .card .card-header{
    min-height: 50px !important;}
`;

export default AppStyleWrapper;
